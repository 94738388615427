<template>
    <div class="route-container">
        <clouds v-if="welcomeTheme === 'CLOUDS'" />
        <astypalaia v-else-if="welcomeTheme === 'ASTYPALAIA'" />
        <clouds-with-gifts v-else />
        <div v-if="freeTasks.length > 0">
            <game
                :free-game="freeTasks[0]"
                class="free-game"
            />
        </div>
        <router-link
            v-if="showGoToRegisterRoute"
            to="/register"
            class="go-to-register-btn"
        >
            Go to Register Route
        </router-link>
    </div>
</template>

<script>
    import Game from '@/components/games/Game'
    import taskService from '@/services/task-service'
    import CloudsWithGifts from '@/components/welcome-backgrounds/CloudsWithGifts'
    import Clouds from '@/components/welcome-backgrounds/Clouds'
    import Astypalaia from '@/components/welcome-backgrounds/Astypalaia'
    import statsHelper from '@/mixins/statsHelper'
    export default {
        components: {
            Game,
            CloudsWithGifts,
            Clouds,
            Astypalaia
        },
        mixins: [statsHelper],
        data() {
            return {
                freeTasks: []
            }
        },
        computed: {
            scanCode() {
                return this.$store.getters.getScanCode
            },
            showGoToRegisterRoute() {
                return this.freeTasks.length === 0 && process.env.VUE_APP_ENV === 'development'
            },
            welcomeTheme() {
                return this.$store.getters.getWelcomeTheme
            }
        },
        created() {
            this.getFreeTasks()
            if (this.scanCode) {
                this.scanQrSpot(this.scanCode, 'QR_SCAN', 'FREE_GAME')
            }
        },
        methods: {
            getFreeTasks() {
                taskService.getFreeTasks().then(res => {
                    this.freeTasks = res.data
                })
            }
        }
    }
</script>

<style scoped>
.route-container {
    position: relative;
}

.free-game {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
}

.go-to-register-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    color: #fff;
    border: 1px solid #fff;
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none;
}
</style>
